const Feature_collections_data = [
    {
        id: "0메이플전적검색",
        bigImage: "/images/maple-portal/collections/0.png",
        subImage1: "/images/collections/collection_1_2.jpg",
        subImage2: "/images/collections/collection_1_3.jpg",
        subImage3: "/images/collections/collection_1_4.jpg",
        userImage: "/images/avatars/owner_5.png",
        userName: "Maple.GG",
        itemsCount: "10K",
        title: "메이플 전적 검색",
        category: ["기타"],
        url: "https://maple.gg/",
        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "1메이플인벤팁게시판",
        bigImage: "/images/maple-portal/collections/1.png",
        subImage1: "/images/collections/collection_2_2.jpg",
        subImage2: "/images/collections/collection_2_3.jpg",
        subImage3: "/images/collections/collection_2_4.jpg",
        userImage: "/images/avatars/owner_9.png",
        userName: "Inven",
        itemsCount: "2.8K",
        title: "메이플 인벤 팁 게시판",
        category: ["기타"],
        url: "https://www.inven.co.kr/board/maple/2304",

        top: false,
        trending: false,
        recent: true,
    },
    {
        id: "2메할일체크리스트",
        bigImage: "/images/maple-portal/collections/2.png",
        subImage1: "/images/collections/collection_3_2.jpg",
        subImage2: "/images/collections/collection_3_3.jpg",
        subImage3: "/images/collections/collection_3_4.jpg",
        userImage: "/images/avatars/owner_4.png",
        userName: "PlanMaple",
        itemsCount: "8K",
        title: "메할일 체크리스트",
        category: ["육성"],
        url: "todo",

        top: true,
        trending: false,
        recent: true,
    },
    {
        id: "3아티팩트계산기",
        bigImage: "/images/maple-portal/collections/3.png",
        subImage1: "/images/collections/collection_4_2.jpg",
        subImage2: "/images/collections/collection_4_3.jpg",
        subImage3: "/images/collections/collection_4_4.jpg",
        userImage: "/images/avatars/owner_10.png",
        userName: "nanosando.github",
        itemsCount: "1.5K",
        title: "아티팩트 계산기",
        category: ["육성"],
        url: "https://nanosando.github.io/maple-union-artifact/",

        top: false,
        trending: true,
        recent: true,
    },
    {
        id: "4도핑체크리스트",
        bigImage: "/images/maple-portal/collections/4.png",
        subImage1: "/images/collections/collection_5_2.jpg",
        subImage2: "/images/collections/collection_5_3.jpg",
        subImage3: "/images/collections/collection_5_4.jpg",
        userImage: "/images/avatars/owner_11.png",
        userName: "051_Hart",
        itemsCount: "15K",
        title: "도핑 체크리스트",
        category: ["재획", "보스"],
        url: "https://maplestory-boss-timer.web.app/",

        top: true,
        trending: false,
        recent: true,
    },
    {
        id: "5재획타이머",
        bigImage: "/images/maple-portal/collections/5.png",
        subImage1: "/images/collections/collection_6_2.jpg",
        subImage2: "/images/collections/collection_6_3.jpg",
        subImage3: "/images/collections/collection_6_4.jpg",
        userImage: "/images/avatars/owner_3.png",
        userName: "Crytopank",
        itemsCount: "8.8K",
        title: "재획 타이머",
        category: ["재획"],
        url: "http://mapleportal.net/timer.html",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "6더시드도우미",
        bigImage: "/images/maple-portal/collections/6.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "더 시드 도우미",
        category: ["육성"],
        url: "https://mapleutils.com/ko/seed/36",

        top: false,
        trending: true,
        recent: true,
    },

    {
        id: "7배고픈무토레시피목록",
        bigImage: "/images/maple-portal/collections/7.png",
        subImage1: "/images/collections/collection_8_2.jpg",
        subImage2: "/images/collections/collection_8_3.jpg",
        subImage3: "/images/collections/collection_8_4.jpg",
        userImage: "/images/avatars/owner_12.png",
        userName: "alyxbow",
        itemsCount: "3K",
        title: "배고픈 무토 레시피 목록",
        category: ["주간 퀘스트"],
        url: "https://maple.gg/info/muto",

        top: true,
        trending: false,
        recent: true,
    },
    {
        id: "8레헬른주퀘도우미",
        bigImage: "/images/maple-portal/collections/8.png",
        subImage1: "/images/collections/collection_1_2.jpg",
        subImage2: "/images/collections/collection_1_3.jpg",
        subImage3: "/images/collections/collection_1_4.jpg",
        userImage: "/images/avatars/owner_5.png",
        userName: "Wow Frens",
        itemsCount: "10K",
        title: "레헬른 주퀘 도우미",
        category: ["주간 퀘스트"],
        url: "https://in-fo.github.io/midnight-chaser-helper/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "9프로텍트에스페라가이드",
        bigImage: "/images/maple-portal/collections/9.png",
        subImage1: "/images/collections/collection_2_2.jpg",
        subImage2: "/images/collections/collection_2_3.jpg",
        subImage3: "/images/collections/collection_2_4.jpg",
        userImage: "/images/avatars/owner_9.png",
        userName: "NFT stars",
        itemsCount: "2.8K",
        title: "프로텍트 에스페라 가이드",
        category: ["주간 퀘스트"],
        url: "https://www.inven.co.kr/board/maple/2304/28788",

        top: false,
        trending: true,
        recent: true,
    },
    {
        id: "10레벨업플래너",
        bigImage: "/images/maple-portal/collections/10.png",
        subImage1: "/images/collections/collection_3_2.jpg",
        subImage2: "/images/collections/collection_3_3.jpg",
        subImage3: "/images/collections/collection_3_4.jpg",
        userImage: "/images/avatars/owner_4.png",
        userName: "Origin Morish",
        itemsCount: "8K",
        title: "레벨업 플래너",
        category: ["육성", "유니온"],
        url: "https://ksw04052.github.io/levelsim/",

        top: true,
        trending: false,
        recent: true,
    },
    {
        id: "11큐브기댓값계산기",
        bigImage: "/images/maple-portal/collections/11.png",
        subImage1: "/images/collections/collection_4_2.jpg",
        subImage2: "/images/collections/collection_4_3.jpg",
        subImage3: "/images/collections/collection_4_4.jpg",
        userImage: "/images/avatars/owner_10.png",
        userName: "Lazy Panda",
        itemsCount: "1.5K",
        title: "큐브 기댓값 계산기",
        category: ["육성"],
        url: "https://cubemesu.co/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "12스타포스시뮬레이터",
        bigImage: "/images/maple-portal/collections/12.png",
        subImage1: "/images/collections/collection_5_2.jpg",
        subImage2: "/images/collections/collection_5_3.jpg",
        subImage3: "/images/collections/collection_5_4.jpg",
        userImage: "/images/avatars/owner_11.png",
        userName: "051_Hart",
        itemsCount: "15K",
        title: "스타포스 시뮬레이터",
        category: ["육성"],
        // url: "https://mesu.live/sim/starforce",
        url: "https://hub.heliseum.com/starforce_simulator",
        top: false,
        trending: true,
        recent: true,
    },
    // {
    //     id: "13스펙투자효율계산기",
    //     bigImage: "/images/maple-portal/collections/13.png",
    //     subImage1: "/images/collections/collection_6_2.jpg",
    //     subImage2: "/images/collections/collection_6_3.jpg",
    //     subImage3: "/images/collections/collection_6_4.jpg",
    //     userImage: "/images/avatars/owner_3.png",
    //     userName: "Crytopank",
    //     itemsCount: "8.8K",
    //     title: "스펙 투자 효율 계산기",
    //     category: ["유니온", "육성"],
    //     url: "https://maple.gg/",
    //
    //     top: true,
    //     trending: true,
    //     recent: true,
    // },
    {
        id: "14사냥터효율계산기",
        bigImage: "/images/maple-portal/collections/14.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "사냥터 효율 계산기",
        category: ["육성", "재획"],
        url: "https://betweenmoon.github.io/maple_hyper/HTML/calcLevelUp.html",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "15환생의불꽃기댓값계산기",
        bigImage: "/images/maple-portal/collections/15.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "환생의 불꽃 기댓값 계산기",
        category: ["육성"],
        url: "https://bnbmupload.github.io/maplerebirthflame/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "16코어배치계산기",
        bigImage: "/images/maple-portal/collections/16.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "코어 배치 계산기",
        category: ["육성", "유니온"],
        url: "https://lastchan.com/v_matrix_calculator",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "17성장의비약계산기",
        bigImage: "/images/maple-portal/collections/17.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "성장의 비약 계산기",
        category: ["육성", "유니온"],
        url: "https://maple.gazua.in/exp",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "18유니온코인수급량계산기",
        bigImage: "/images/maple-portal/collections/18.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "유니온 코인 수급량 계산기",
        category: ["육성", "유니온"],
        url: "https://gubbib.github.io/MapleUnionCoin/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "19유니온배치계산기",
        bigImage: "/images/maple-portal/collections/19.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "유니온 배치 계산기",
        category: ["육성", "유니온"],
        url: "https://xenogents.github.io/LegionSolver/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "20무릉수로환산기",
        bigImage: "/images/maple-portal/collections/20.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "무릉 수로 환산기",
        category: ["육성"],
        url: "https://141.kr/suro",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "21주보결정석계산기",
        bigImage: "/images/maple-portal/collections/21.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "주보 결정석 계산기",
        category: ["육성", "보스"],
        url: "https://boss.lastchan.kr/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "22사냥메소계산기",
        bigImage: "/images/maple-portal/collections/22.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "사냥 메소 계산기",
        category: ["육성"],
        url: `maple_portal/meso_calc`,
        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "23아케인심볼계산기",
        bigImage: "/images/maple-portal/collections/23.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "아케인심볼 계산기",
        category: ["육성"],
        url: "https://maple-util.web.app/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "24환산스탯계산기",
        bigImage: "/images/maple-portal/collections/24.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "환산스탯 계산기",
        category: ["육성"],
        url: "https://maplescouter.com/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "25보스보상분배계산기",
        bigImage: "/images/maple-portal/collections/25.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "보스 보상 분배 계산기",
        category: ["육성", "보스"],
        url: "https://141.kr/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "26검은마법사타이머",
        bigImage: "/images/maple-portal/collections/26.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "검은 마법사 타이머",
        category: ["보스"],
        url: "https://ksw04052.github.io/blackmage/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "27칼로스타이머",
        bigImage: "/images/maple-portal/collections/27.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "칼로스 타이머",
        category: ["보스"],
        url: "http://kalos.dothome.co.kr/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "29진힐라타이머",
        bigImage: "/images/maple-portal/collections/29.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "진힐라 타이머",
        category: ["보스"],
        url: "https://maple.gg/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "30메이플코디시뮬레이터",
        bigImage: "/images/maple-portal/collections/30.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "메이플 코디 시뮬레이터",
        category: ["육성"],
        url: "https://maple.gazua.in/coordi",

        top: true,
        trending: true,
        recent: true,
    },
    // {
    //     id: "31하이퍼스탯계산기",
    //     bigImage: "/images/maple-portal/collections/31.png",
    //     subImage1: "/images/collections/collection_7_2.jpg",
    //     subImage2: "/images/collections/collection_7_3.jpg",
    //     subImage3: "/images/collections/collection_7_4.jpg",
    //     userImage: "/images/avatars/creator_2.png",
    //     userName: "Hey Mrsmeseks",
    //     itemsCount: "13K",
    //     title: "하이퍼스탯 계산기",
    //     category: ["육성"],
    //     url: "http://mapleportal.net/hstat.html",
    //
    //     top: true,
    //     trending: true,
    //     recent: true,
    // },
    {
        id: "32메이플가챠시뮬레이터",
        bigImage: "/images/maple-portal/collections/32.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "메이플 가챠 시뮬레이터",
        category: ["육성"],
        url: "https://maple.gazua.in/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "33헥사스탯시뮬레이터",
        bigImage: "/images/maple-portal/collections/33.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "헥사스탯 시뮬레이터",
        category: ["육성"],
        url: "https://memoday.github.io/hexaStat_Simulator/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "34본캐추적사이트",
        bigImage: "/images/maple-portal/collections/34.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "ufshg.github",
        itemsCount: "13K",
        title: "본캐 추적 사이트",
        category: ["기타"],
        url: "https://ufshg.github.io/nbm/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "35아이템시뮬레이터",
        bigImage: "/images/maple-portal/collections/35.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "아이템 시뮬레이터",
        category: ["육성"],
        url: "https://itemsim.pages.dev/",

        top: true,
        trending: true,
        recent: true,
    },
    {
        id: "36몬스터컬렉션도우미",
        bigImage: "/images/maple-portal/collections/36.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "몬스터 컬렉션 도우미",
        category: ["육성"],
        url: "https://www.moncol.kr/",

        top: true,
        trending: true,
        recent: true,
    },
    // {
    //     id: "37판정범위보는사이트",
    //     bigImage: "/images/collections/collection_7_1.jpg",
    //     subImage1: "/images/collections/collection_7_2.jpg",
    //     subImage2: "/images/collections/collection_7_3.jpg",
    //     subImage3: "/images/collections/collection_7_4.jpg",
    //     userImage: "/images/avatars/creator_2.png",
    //     userName: "Hey Mrsmeseks",
    //     itemsCount: "13K",
    //     title: "판정 범위 보는 사이트",
    //     category: ["육성"],
    //     top: true,
    //     trending: true,
    //     recent: true,
    // },
    // {
    //     id: "38필터링단어사전",
    //     bigImage: "/images/collections/collection_7_1.jpg",
    //     subImage1: "/images/collections/collection_7_2.jpg",
    //     subImage2: "/images/collections/collection_7_3.jpg",
    //     subImage3: "/images/collections/collection_7_4.jpg",
    //     userImage: "/images/avatars/creator_2.png",
    //     userName: "Hey Mrsmeseks",
    //     itemsCount: "13K",
    //     title: "필터링 단어 사전",
    //     category: ["기타"],
    //     top: true,
    //     trending: true,
    //     recent: true,
    // },
    {
        id: "39경매장사이트",
        bigImage: "/images/maple-portal/collections/39.png",
        subImage1: "/images/collections/collection_7_2.jpg",
        subImage2: "/images/collections/collection_7_3.jpg",
        subImage3: "/images/collections/collection_7_4.jpg",
        userImage: "/images/avatars/creator_2.png",
        userName: "Hey Mrsmeseks",
        itemsCount: "13K",
        title: "경매장 사이트",
        category: ["기타"],
        url: "https://xn--hz2b1j494a9mhnwh.com/",

        top: true,
        trending: true,
        recent: true,
    },
    // {
    //     id: "40메이플스토리게임런처",
    //     bigImage: "/images/collections/collection_7_1.jpg",
    //     subImage1: "/images/collections/collection_7_2.jpg",
    //     subImage2: "/images/collections/collection_7_3.jpg",
    //     subImage3: "/images/collections/collection_7_4.jpg",
    //     userImage: "/images/avatars/creator_2.png",
    //     userName: "Hey Mrsmeseks",
    //     itemsCount: "13K",
    //     title: "메이플스토리 게임런처",
    //     category: ["기타"],
    //     top: true,
    //     trending: true,
    //     recent: true,
    // },
    // {
    //     id: "41사기꾼박제센터",
    //     bigImage: "/images/collections/collection_7_1.jpg",
    //     subImage1: "/images/collections/collection_7_2.jpg",
    //     subImage2: "/images/collections/collection_7_3.jpg",
    //     subImage3: "/images/collections/collection_7_4.jpg",
    //     userImage: "/images/avatars/creator_2.png",
    //     userName: "Hey Mrsmeseks",
    //     itemsCount: "13K",
    //     title: "사기꾼 박제센터",
    //     category: ["기타"],
    //     top: true,
    //     trending: true,
    //     recent: true,
    // },
    // {
    //     id: "42데미지스킨정리해둔곳",
    //     bigImage: "/images/collections/collection_7_1.jpg",
    //     subImage1: "/images/collections/collection_7_2.jpg",
    //     subImage2: "/images/collections/collection_7_3.jpg",
    //     subImage3: "/images/collections/collection_7_4.jpg",
    //     userImage: "/images/avatars/creator_2.png",
    //     userName: "Hey Mrsmeseks",
    //     itemsCount: "13K",
    //     title: "데미지스킨 정리해둔 곳",
    //     category: ["기타"],
    //     top: true,
    //     trending: true,
    //     recent: true,
    // },
    // {
    //     id: "43메이플서버시간",
    //     bigImage: "/images/collections/collection_7_1.jpg",
    //     subImage1: "/images/collections/collection_7_2.jpg",
    //     subImage2: "/images/collections/collection_7_3.jpg",
    //     subImage3: "/images/collections/collection_7_4.jpg",
    //     userImage: "/images/avatars/creator_2.png",
    //     userName: "Hey Mrsmeseks",
    //     itemsCount: "13K",
    //     title: "메이플 서버 시간",
    //     category: ["기타"],
    //     top: true,
    //     trending: true,
    //     recent: true,
    // },
];

export default Feature_collections_data;
