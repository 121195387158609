/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Explore_collection_item from "../../components/collectrions/explore_collection_item_maple_portal";
import { HeadLine } from "../../components/component";
import Collection_dropdown from "../../components/dropdown/collection_dropdown_maple_portal";
import Meta from "../../components/Meta";
import { tranding_category_filter } from "../../data/categories_data_maple_portal";
import Feature_collections_data from "../../data/Feature_collections_data_maple_portal";
import { collectCollectionData } from "../../redux/counterSlice";
import DarkMode from "../../components/mode/DarkMode";

const Index = () => {
  const dispatch = useDispatch();
  const [collectionFilteredData, setCollectionFilteredData] = useState(Feature_collections_data);
  const [filterVal, setFilterVal] = useState(0);


  const handleItemFilter = (text: string) => {
    if (text === "all") {
      setCollectionFilteredData(Feature_collections_data);
    } else if (text === "즐겨찾기") {
      // @ts-ignore
      const savedLikes = JSON.parse(localStorage.getItem("likedItems")) || [];
      // console.log(savedLikes);
      const likedItems = Feature_collections_data.filter((item) => savedLikes.includes(item.id));
      setCollectionFilteredData(likedItems);
    } else {
      // setCollectionFilteredData(Feature_collections_data.filter((item) => item.category === text));
      setCollectionFilteredData(Feature_collections_data.filter((item) => item.category.includes(text)));
    }
  };

  useEffect(() => {
    dispatch(collectCollectionData(collectionFilteredData.slice(0, 99)));
  }, [dispatch, collectionFilteredData]);


  return (
    <>
      <Meta title="허브" url="hub.heliseum.com" description="메이플스토리 플레이에 도움되는 유틸리티를 모아놓은 허브입니다."
      />
      <section className="relative mt-24 lg:pb-48 pb-24">
        <DarkMode/>
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            width={1519}
            height={773}
            priority
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full"
          />
        </picture>

        <div className="container">
          {/*@ts-ignore                   */}
          <HeadLine
            text="헬리시움 허브"
            classes="font-display text-jacarta-700 py-16 text-center text-5xl font-bold dark:text-white"
          />

          {/* <!-- Filter --> */}
          <div className="mb-8 flex flex-wrap items-start justify-between">
            <ul className="flex flex-wrap items-center">
              {tranding_category_filter.map(({ id, svg, text }) => {
                if (text === "all") {
                  return (
                    <li
                      className="my-1 mr-2.5"
                      key={id}
                      onClick={() => {
                        handleItemFilter(text);
                        setFilterVal(id);
                      }}
                    >
                      <button
                        className={
                          filterVal === id
                            ? " group bg-accent font-display flex h-9 items-center justify-center rounded-lg px-4 text-sm font-semibold transition-colors border-transparent text-white capitalize"
                            : "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white capitalize"
                        }
                      >
                        {text}
                      </button>
                    </li>
                  );
                } else {
                  return (
                    <li
                      className="my-1 mr-2.5"
                      key={id}
                      onClick={() => {
                        handleItemFilter(text);
                        setFilterVal(id);
                      }}
                    >
                      <button
                        className={
                          filterVal === id
                            ? "dark:border-jacarta-600 bg-accent group border-jacarta-100 font-display flex h-9 items-center rounded-lg border px-4 text-sm font-semibold transition-colors border-transparent dark:border-transparent text-white"
                            : "dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white"
                        }
                      >
                        {/*<svg*/}
                        {/*    className={*/}
                        {/*        filterVal === id*/}
                        {/*            ? "icon mr-1 h-4 w-4 transition-colors fill-white"*/}
                        {/*            : "icon fill-jacarta-700 dark:fill-jacarta-100 mr-1 h-4 w-4 transition-colors group-hover:fill-white"*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*</svg>*/}
                        <Image
                          width={16}
                          height={16}
                          src={`/images/maple-portal/${svg}.png`}
                          alt={svg || "alternative-text"}  // alt 속성에 기본값 설정
                          className="mr-2.5"
                        />

                        <span>{text}</span>
                      </button>
                    </li>
                  );
                }
              })}
            </ul>
            {/* dropdown */}
            {/*<Collection_dropdown />*/}
          </div>

          {/* <!-- Grid --> */}
          <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-4">
            <Explore_collection_item itemFor="explore-collection" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
