const tranding_category_filter = [
    {
        id: 0,
        text: "all",
    },
    {
        id: 7,
        svg: "grow",
        text: "즐겨찾기",
    },
    {
        id: 1,
        svg: "union",
        text: "유니온",
    },

    {
        id: 2,
        svg: "mailbox",
        text: "주간 퀘스트",
    },
    {
        id: 3,
        svg: "hunt",
        text: "재획",
    },
    {
        id: 4,
        svg: "boss",
        text: "보스",
    },
    {
        id: 5,
        svg: "etc",
        text: "기타",
    },
    {
        id: 6,
        svg: "grow",
        text: "육성",
    },
];

export {tranding_category_filter};
