import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Feature_collections_data from "../../data/Feature_collections_data_maple_portal";
import Image from "next/image";
import { useRouter } from "next/router";

const Explore_collection_item = ({ itemFor }) => {
  const { sortedCollectionData } = useSelector((state) => state.counter);
  const [likesImageList, setLikesImageList] = useState([]);

  const [itemData, setItemData] = useState([]);
  const handleLikes = (itemId) => {
    // 이전에 정의된 updatedLikes 변수 제거
    setLikesImageList((prevLikes) => {
      const updatedLikes = [...prevLikes];
      const index = updatedLikes.indexOf(itemId);
      if (index === -1) {
        updatedLikes.push(itemId);
      } else {
        updatedLikes.splice(index, 1);
      }

      // 로컬스토리지에 즐겨찾기한 아이템의 ID들을 저장
      localStorage.setItem('likedItems', JSON.stringify(updatedLikes));

      return updatedLikes;
    });
  };

  useEffect(() => {
    // 로컬스토리지에서 즐겨찾기한 아이템의 ID들을 불러옴
    const savedLikes = JSON.parse(localStorage.getItem('likedItems')) || [];
    // console.log(savedLikes);
    setLikesImageList(savedLikes);
  }, []); // 컴포넌트가 마운트될 때만 실행
  useEffect(() => {
    if (itemFor === "userPage") {
      setItemData(Feature_collections_data.slice(0, 4));

      // console.log(itemData);
    } else {
      setItemData(sortedCollectionData);
    }
  }, [sortedCollectionData, itemFor]);




  return (
    <>
      {itemData.map((item) => {

        const {
          id,
          bigImage,
          url,
          subImage1,
          subImage2,
          subImage3,
          userImage,
          title,
          itemsCount,
          userName,
        } = item;

        // const handleLikes = () => {
        //   if (!likesImage) {
        //     setLikesImage(true);
        //     console.log(item.id)
        //     localStorage.setItem(`likedItem_${id}`, `true`);
        //     console.log("button clicked")
        //   } else {
        //     localStorage.removeItem(`likedItem_${id}`);
        //     setLikesImage(false);
        //   }
        // };
        const isLiked = likesImageList.includes(id);

        return (
          <article key={id}>
            <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2xl border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
              <Link
                href={url}
                target="_blank"
                className="flex space-x-[0.625rem]"
              >
                {/*<span className="w-[100%]">*/}
                {/*  <Image*/}
                {/*    width={152}*/}
                {/*    height={242}*/}
                {/*    src={bigImage}*/}
                {/*    alt="item 1"*/}
                {/*    className="h-full w-full rounded-[0.625rem] object-cover"*/}
                {/*    loading="lazy"*/}
                {/*  />*/}
                {/*</span>*/}
                <span className="flex w-1/3 flex-col space-y-[0.625rem]">
                  <Image
                    width={68}
                    height={74}
                    // src={subImage1}
                    src={bigImage}
                    alt="item 1"
                    className="h-full w-full rounded-[0.625rem] object-cover"
                    loading="lazy"
                  />
                  {/*<Image*/}
                  {/*  width={68}*/}
                  {/*  height={74}*/}
                  {/*  src={subImage2}*/}
                  {/*  alt="item 1"*/}
                  {/*  className="h-full w-full rounded-[0.625rem] object-cover"*/}
                  {/*  loading="lazy"*/}
                  {/*/>*/}
                  {/*<Image*/}
                  {/*  width={68}*/}
                  {/*  height={74}*/}
                  {/*  src={subImage3}*/}
                  {/*  alt="item 1"*/}
                  {/*  className="h-full w-full rounded-[0.625rem] object-cover"*/}
                  {/*  loading="lazy"*/}
                  {/*/>*/}
                </span>
              </Link>

              <Link
                href={url}
                className="font-display hover:text-accent dark:hover:text-accent text-jacarta-700 mt-4 block text-base dark:text-white"
                target="_blank"
              >
                {title}
              </Link>
              <div onClick={()=>handleLikes(id)}>
                <button className="js-likes relative cursor-pointer" >
                  {isLiked ? (
                    // 좋아요 상태에 따라 이미지 변경
                    <svg className="icon icon-heart-fill dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                      <use xlinkHref="/icons.svg#icon-heart-fill"></use>
                    </svg>
                  ) : (
                    <svg className="icon icon-heart-fill dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
                      <use xlinkHref="/icons.svg#icon-hert-fill"></use>
                    </svg>
                  )}
                </button>
              </div>

              {/*<div className="mt-2 flex items-center justify-between text-sm font-medium tracking-tight">*/}
              {/*  <div className="flex flex-wrap items-center">*/}
              {/*    <Link href="/user/avatar_6" className="mr-2 shrink-0">*/}
              {/*      <Image*/}
              {/*        width={20}*/}
              {/*        height={20}*/}
              {/*        src={userImage}*/}
              {/*        alt="owner"*/}
              {/*        className="h-5 w-5 rounded-full"*/}
              {/*      />*/}
              {/*    </Link>*/}
              {/*    <span className="dark:text-jacarta-400 mr-1">by</span>*/}
              {/*    <Link href="" className="text-accent" >*/}
              {/*      <span>{userName}</span>*/}
              {/*    </Link>*/}
              {/*  </div>*/}
              {/*  <span className="dark:text-jacarta-300 text-sm">*/}
              {/*    {itemsCount} Items*/}
              {/*  </span>*/}
              {/*</div>*/}
            </div>
          </article>
        );
      })}
    </>
  );
};

export default Explore_collection_item;
